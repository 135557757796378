<script lang="ts">
	import state from '$lib/stores/auth-state'
	import { getContextClient } from '@urql/svelte'
	import Button from '../buttons/Button.svelte'
	import PhoneFrame from './PhoneFrame.svelte'
	import TripScreen from './TripScreen.svelte'
	import { track } from '$src/utils/track'
	import { goto } from '$app/navigation'
	import {
		InsertTripDocument,
		type InsertTripMutation,
		type InsertTripMutationVariables,
	} from '$src/lib/queries/generated/InsertTrip'
	import { clearInsertTripOnLoadParams, loginAndInsertTrip } from '$src/utils/insertTrip'
	import { performSignIn } from '$src/lib/authentication/helpers'
	import BackgroundIllustration from './BackgroundIllustration.svelte'

	const client = getContextClient()

	async function handleInsertTrip() {
		insertTripMutation({ input: { title: 'New Trip' } })
			.then((res) => {
				if (res?.data?.insertTrip?.id) {
					goto(`/trips/${res?.data?.insertTrip?.id}`)
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const insertTripMutation = (vars: InsertTripMutationVariables) =>
		client
			.mutation<InsertTripMutation, InsertTripMutationVariables>(InsertTripDocument, vars)
			.toPromise()

	function handleStartTripClick() {
		track('Start Trip Clicked')
		if ($state.signedIn) {
			handleInsertTrip()
		} else {
			loginAndInsertTrip(
				performSignIn,
				() => handleInsertTrip().then(clearInsertTripOnLoadParams),
				null,
			)

			return Promise.resolve()
		}
	}

	function exploreGuidesClick() {
		track('Explore Guides Clicked')
		goto('/explore')
	}
</script>

<div class="overflow-hidden pt-16 pb-24 sm:py-24 lg:py-32 xl:py-36">
	<div class="mx-auto max-w-7xl px-4 sm:px-6">
		<div class="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
			<div class="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 xl:col-span-6">
				<!-- <BackgroundIllustration
					class="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-brand-gray-3/70 [mask-image:linear-gradient(to_bottom,white_40%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0"
				/> -->
				<div
					class="-mx-4 h-[448px] px-4 sm:px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32"
				>
					<PhoneFrame>
						<TripScreen />
					</PhoneFrame>
				</div>
			</div>
			<div class="relative z-10 max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-32 xl:col-span-6">
				<h2
					class="text-3xl font-extrabold tracking-tight text-black sm:text-4xl lg:text-5xl sm:leading-normal my-2 lg:my-4"
				>
					Find Your Next Adventure
					<!-- <br /> -->
					<!-- <span class="text-brand-primary">Adventure</span> -->
				</h2>

				<div class="space-y-6">
					<p class="max-w-2xl leading-normal">
						Gain inspiration from knowledgeable travelers and design the trips of your dreams all in
						one beautiful, integrated space.
					</p>

					<div
						class="flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 sm:gap-x-4 justify-start mt-8 w-full"
					>
						<!-- <Button
							theme="dark"
							priority="secondary"
							class="w-full sm:w-auto"
							size="md"
							on:click={handleStartTripClick}>Start Your Trip</Button
						> -->
						<Button
							theme="dark"
							priority="secondary"
							class="w-full sm:w-auto"
							size="md"
							on:click={exploreGuidesClick}>Explore Guides</Button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
