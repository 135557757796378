<script lang="ts">
	import Logo from './Logo.svelte'
</script>

<div class="bg-white">
	<div class="max-w-5xl py-12 mx-auto lg:py-16 relative isolate">
		<!-- <div
			class="absolute inset-x-0 -top-12 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
			aria-hidden="true"
		>
			<div
				class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-t from-brand-gray-0 to-brand-primary opacity-25"
				style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
			/>
		</div> -->
		<p class="text-center">
			<span class="py-1 text-base font-semibold tracking-wide">As Seen On</span>
		</p>

		<div class="mt-4 grid grid-cols-2 gap-0.5 md:grid-cols-5">
			<Logo
				link="https://ktrh.iheart.com/content/2021-05-20-international-travel-is-still-complicated/"
				src={'/visuals/press-logos/bw/iheart-radio.png'}
				alt="iHeart Radio"
			/>

			<Logo
				link="https://www.forbes.com/sites/christopherelliott/2021/05/22/do-i-need-a-covid-test-to-travel-and-other-summer-travel-questions/?sh=5fd6da071c31"
				src={'/visuals/press-logos/bw/forbes.png'}
				alt="Forbes"
			/>
			<Logo
				link="https://www.washingtonpost.com/travel/tips/europe-travel-us-summer-travel/"
				src={'/visuals/press-logos/bw/washington-post.png'}
				alt="Washington Post"
			/>
			<Logo
				link="https://www.johnnyjet.com/airheart-helps-travelers-navigate-travel-entry-requirements"
				src={'/visuals/press-logos/bw/johnny-jet.png'}
				alt="Johnny Jet"
			/>
			<Logo
				link="https://www.builtinaustin.com/2021/10/19/austin-future-5-startups-q4-2021"
				src={'/visuals/press-logos/bw/builtin.png'}
				alt="BuiltIn"
			/>

			<Logo
				link="https://www.travelpulse.com/gallery/destinations/independence-day-2021-10-safest-states-to-visit-this-4th-of-july.html"
				src={'/visuals/press-logos/bw/travel-pulse.png'}
				alt="Travel Pulse"
			/>
			<Logo
				link="https://twitter.com/nomadicmatt/status/1402612009057783811?s=20"
				src={'/visuals/press-logos/bw/nomadic-matt.png'}
				alt="Nomadic Matt"
			/>
			<Logo
				link="https://skift.com/2021/10/08/kyte-raises-30-million-for-rental-cars-travel-startup-funding-this-week/"
				src={'/visuals/press-logos/bw/skift.png'}
				alt="Skift"
			/>
			<Logo
				link="https://www.businesstravelerusa.com/business-traveler-usa-story/new-app-helps-travelers-navigate-complex-border-restrictions-"
				src={'/visuals/press-logos/bw/business-traveler.png'}
				alt="Business Traveler"
			/>

			<Logo
				link="https://www.cbsnews.com/radio/"
				src={'/visuals/press-logos/bw/cbs-news.png'}
				alt="CBS News Radio"
			/>
		</div>
	</div>
</div>
