<script lang="ts">
	import cdnImageURL from '$src/utils/cdn'
	import { onMount, afterUpdate } from 'svelte'

	let parallaxOffset = 0
	let parallaxOffsetTwo = 0
	let parallaxOffsetThree = 0
	let startOffset = 0
	let startOffsetTwo = 0
	let startOffsetThree = 0
	let parallaxElement: HTMLElement
	let parallaxElementTwo: HTMLElement
	let parallaxElementThree: HTMLElement

	const updateParallax = () => {
		const scrolled = window.scrollY
		const distanceToScrollStart = startOffset - scrolled
		const distanceToScrollStartTwo = startOffsetTwo - scrolled
		const distanceToScrollStartThree = startOffsetThree - scrolled
		// Only apply parallax effect when the element is in view
		if (
			distanceToScrollStart <= window.innerHeight &&
			distanceToScrollStart >= -parallaxElement.offsetHeight
		) {
			parallaxOffset = (scrolled - startOffset) * 0.2
		}

		if (
			distanceToScrollStartTwo <= window.innerHeight &&
			distanceToScrollStartTwo >= -parallaxElementTwo.offsetHeight
		) {
			parallaxOffsetTwo = (scrolled - startOffsetTwo) * 0.2
		}

		if (
			distanceToScrollStartThree <= window.innerHeight &&
			distanceToScrollStartThree >= -parallaxElementThree.offsetHeight
		) {
			parallaxOffsetThree = (scrolled - startOffsetThree) * 0.2
		}
	}

	onMount(() => {
		startOffset = parallaxElement.getBoundingClientRect().top + window.scrollY
		startOffsetTwo = parallaxElementTwo.getBoundingClientRect().top + window.scrollY
		startOffsetThree = parallaxElementThree.getBoundingClientRect().top + window.scrollY
		window.addEventListener('scroll', updateParallax)

		return () => {
			window.removeEventListener('scroll', updateParallax)
		}
	})

	afterUpdate(() => {
		// Update the start offset if the layout changes
		startOffset = parallaxElement.getBoundingClientRect().top + window.scrollY
		startOffsetTwo = parallaxElementTwo.getBoundingClientRect().top + window.scrollY
		startOffsetThree = parallaxElementThree.getBoundingClientRect().top + window.scrollY
	})
</script>

<section class="lg:relative lg:flex bg-black">
	<div class="relative overflow-hidden w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center">
		<div class="inset-0 object-cover object-center w-full h-[760px] bg-black" />
		<div
			class="absolute my-auto h-full flex flex-col items-left justify-center mx-auto px-6 lg:px-8 text-white"
		>
			<h2
				class="mb-8 text-pretty max-w-sm text-white text-xl sm:text-3xl font-extrabold leading-8 tracking-tight sm:leading-9"
			>
				Elevate Your Adventures: Personalize or Pick the Perfect Path
			</h2>
			<div class="text-base max-w-md text-white leading-7 py-auto">
				<p>
					Maximize your travel experiences with a dedicated platform that offers you the flexibility
					to craft your dream itineraries from scratch or get inspired by purchasing guides created
					by seasoned travelers.
				</p>

				<p class="mt-4">
					Whether your plans are still in the dream phase or you're ready to set off on your next
					big adventure, tap into a wealth of knowledge and inspiration. Dream big and plan with
					confidence.
				</p>
			</div>
		</div>
	</div>
	<div
		bind:this={parallaxElement}
		class="relative overflow-hidden w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center"
	>
		<div
			class="inset-0 object-cover object-center w-full h-[760px]"
			style="background-image: url('/visuals/stock/como.webp'); background-attachment: fixed; transform: translateY({parallaxOffset}px);"
		/>
	</div>
</section>

<section class="relative flex flex-col-reverse lg:flex-row bg-black">
	<div
		bind:this={parallaxElementTwo}
		class="relative overflow-hidden w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center"
	>
		<div
			class="inset-0 object-cover object-center w-full h-[760px]"
			style="
						background-image: url('/visuals/stock/donkey.webp');
						background-attachment: fixed;
            background-position: center 60%;
						transform: translateY({parallaxOffsetTwo}px);
					"
		/>
	</div>
	<div class="relative overflow-hidden w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center">
		<div class="inset-0 object-cover object-center w-full h-[760px] bg-black" />
		<div
			class="absolute my-auto h-full flex flex-col items-left justify-center mx-auto px-6 lg:px-8 text-white"
		>
			<span class="text-white tracking-widest text-sm sm:text-base h-5 mt-9" />
			<h2
				class="mb-8 text-pretty max-w-sm text-white text-xl sm:text-3xl font-extrabold leading-8 tracking-tight sm:leading-9"
			>
				With Supplimentary Data, Be Prepared for Whatever Comes Your Way
			</h2>
			<div class="text-base max-w-md text-white font-normal leading-7 py-auto">
				<p>
					Access travel requirements, safety scores, and optimized routing between locations,
					providing you with critical information that goes beyond typical recommendations.
				</p>
				<p class="mt-4">
					In the future, we’ll be expanding to make planning seamless and keeps you informed every
					step of the way. Enjoy the peace of mind that comes from having all the logistics
					covered—so you can focus on experiencing the journey, not just planning it.
				</p>
			</div>
		</div>
	</div>
</section>

<section class="lg:relative lg:flex bg-black">
	<div class="relative overflow-hidden w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center">
		<div class="inset-0 object-cover object-center w-full h-[760px] bg-black" />
		<div
			class="absolute my-auto h-full flex flex-col items-left justify-center mx-auto px-6 lg:px-8 text-white"
		>
			<span class="text-white tracking-widest text-sm sm:text-base">COMING SOON</span>
			<h2
				class="max-w-sm text-pretty text-white mb-8 text-xl font-extrabold leading-8 tracking-tight sm:text-3xl sm:leading-snug"
			>
				Organize Group Travel Effectively Like Never Before
			</h2>
			<div class="text-base font-normal max-w-md text-white leading-relaxed py-auto">
				<p>
					Organizing group travel often feels like herding cats: challenging and sometimes downright
					chaotic. The task of gathering everyone's preferences and reservation details can be so
					daunting that embracing the chaos might seem like the lesser of two evils.
				</p>

				<p class="mt-4">
					That's where Airheart steps in. We streamline the coordination, ensuring you can
					concentrate on the joy of the journey alongside your companions.
				</p>
			</div>
		</div>
	</div>
	<div
		bind:this={parallaxElementThree}
		class="relative overflow-hidden w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center"
	>
		<div
			class="inset-0 object-cover object-center w-full h-[760px]"
			style="
					background-image: url('/visuals/stock/morocco.webp');
					background-attachment: fixed;
            background-position: center 60%;
					transform: translateY({parallaxOffsetThree}px);"
		/>
	</div>
</section>
