<script lang="ts">
	import { getContextClient, queryStore } from '@urql/svelte'
	import {
		ExploreTripsDocument,
		type ExploreTripsQuery,
		type ExploreTripsQueryVariables,
	} from '$lib/queries/generated/QueryListings'
	import { browser } from '$app/environment'
	import { handleGraphQLError } from '$src/utils/handleGraphQLErrors'
	import { track } from '$src/utils/track'
	import { navigating, page } from '$app/stores'
	import { goto, preloadData } from '$app/navigation'
	import ExploreTripCard from '../itineraries/ExploreTripCard.svelte'
	import Button from '../buttons/Button.svelte'

	export let published: ExploreTripsQuery['exploreTrips'] = []

	// const urql = getContextClient()
	// $: tripsStore = queryStore<ExploreTripsQuery, ExploreTripsQueryVariables>({
	// 	client: urql,
	// 	variables: { isHomepage: true },
	// 	query: ExploreTripsDocument,
	// 	pause: !browser,
	// })

	// $: if ($tripsStore?.error) {
	// 	handleGraphQLError($tripsStore?.error)
	// }

	// $: published = $tripsStore?.data?.exploreTrips
	// $: fetching = $tripsStore?.fetching

	let nextIntentID = null

	function buildTripURL(tripID: string) {
		let nextURL = new URL(`/explore/${tripID}`, $page?.url)
		return nextURL
	}

	function handleClickTrip(listingId: string) {
		track('Explore Trip', { listingId: listingId })
		let nextURL = buildTripURL(listingId)

		setTimeout(() => {
			if ($navigating) {
				nextIntentID = listingId
			}
		}, 100)

		goto(nextURL.toString())
	}

	function preloadListingPage(tripID: string) {
		let nextURL = buildTripURL(tripID)
		return preloadData(nextURL.toString())
	}

	function exploreGuidesClick() {
		track('Explore Guides Clicked')
		goto('/explore')
	}
</script>

<div class="bg-white pt-12 pb-40 lg:pt-24 lg:pb-94">
	<div class="max-w-7xl mx-auto relative isolate px-4 lg:px-16">
		<!-- <div
			class="absolute inset-x-0 -top-12 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
			aria-hidden="true"
		>
			<div
				class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-t from-brand-gray-0 to-brand-primary opacity-25"
				style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
			/>
		</div> -->
		<!-- <div class="text-center pb-4">
			<h2
				class="text-pretty text-xl font-extrabold leading-8 tracking-tight sm:text-3xl sm:leading-9"
			>
				Trips Planned For You
			</h2>
			<p class="mt-3">
				Purchase pre-planned trips, expertly crafted, and designed for a seamless travel experience.
			</p>
		</div> -->
		<!-- {#if fetching} -->
		<!-- <div
				class="flex items-center justify-center min-h-16 text-center pt-4 text-black dark:text-white w-full flex-shrink-0 max-h-[70vh] lg:max-h-none overflow-y-auto rounded-t-xl lg:rounded-none"
			>
				<div
					class="border-brand-gray-6 dark:border-brand-gray-6 animate-spin rounded-full h-16 w-16 border-b-2"
				/>
			</div> -->
		<!-- {:else if published && published?.length > 0} -->
		<div class="mt-4 grid grid-cols-1 gap-8 md:grid-cols-4">
			{#each published as listing}
				<ExploreTripCard
					vertical={true}
					trip={listing?.currentTrip}
					fetching={nextIntentID === listing?.currentTrip?.id}
					on:click={() => handleClickTrip(listing?.id)}
					on:mouseenter={() => preloadListingPage(listing?.id)}
				/>
			{/each}
		</div>
		<!-- {/if} -->

		<!-- <Button
			theme="dark"
			priority="secondary"
			class="w-full sm:w-auto mx-auto mt-8"
			size="md"
			on:click={exploreGuidesClick}>Explore Guides</Button
		> -->
	</div>
</div>
