<script lang="ts">
	import MainHero from '$lib/components/marketing/MainHero.svelte'
	import Mission from '$lib/components/marketing/Mission.svelte'
	// import PopularDestinations from '$lib/components/landing/PopularDestinations.svelte'
	import PressLogos from '$lib/components/marketing/PressLogos.svelte'
	import SuperPowers from '$lib/components/marketing/SuperPowers.svelte'
	// import TravelerTips from '$lib/components/landing/TravelerTips.svelte'
	// import HotelPreview from '$lib/components/landing/HotelPreview.svelte'
	import SvelteSeo from 'svelte-seo'
	import type { PageData, PageServerData } from './$types'
	import JoinCta from '$src/lib/components/marketing/JoinCTA.svelte'
	import SocialProof from '$src/lib/components/marketing/SocialProof.svelte'
	import MainMission from '$lib/components/marketing/MainMission.svelte'
	import Guides from '$lib/components/marketing/Guides.svelte'
	import Trips from '$lib/components/marketing/Trips.svelte'
	// import PopularVisas from '$lib/components/landing/PopularVisas.svelte'

	export let data: PageServerData

	$: published = data.tripsData?.exploreTrips

	// $: tipsData = data.tipsData
	$: title = data?.title
	$: description = data?.description

	$: images = [
		{
			url: `https://airheart.com/airheart-social.png`,
			width: 1200,
			height: 600,
			alt: title,
		},
	]
</script>

<SvelteSeo
	twitter={{
		description,
		title,
		image: `https://airheart.com/airheart-social.png`,
		imageAlt: title,
		site: '@airheartinc',
		card: 'summary_large_image',
	}}
	openGraph={{
		title,
		images,
		description,
	}}
/>

<div class="col-span-12 bg-white">
	<MainHero />
	<!-- <MainMission /> -->
	<Trips {published} />
	<SuperPowers />
	<SocialProof />
	<PressLogos />
	<Guides />
	<JoinCta />
</div>
