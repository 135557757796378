<script lang="ts">
	import { goto } from '$app/navigation'
	import { track } from '$src/utils/track'
	import { Icon } from '@steeze-ui/svelte-icon'
	import Guide from './Guide.svelte'
	import { ArrowRight } from '@steeze-ui/heroicons'

	const guides = [
		{
			imageUrl: '4d195b25-abaa-4823-4d08-94df2c328201',
			name: 'Mercedes',
			instagram: 'calculatedopulence',
			username: 'Calculatedopulence',
			tiktok: 'calculatedopulence',
		},
		{
			imageUrl: 'ed3c07c2-25c3-4680-27fc-c3380995e900',
			name: 'Payton Cavin',
			instagram: 'mellowyellowpay',
			username: 'mellowyellowpay',
			tiktok: 'mellowyellowpay',
		},
		{
			imageUrl: '7cafcdf0-236c-4c05-076c-902479d39001',
			name: 'Estefania Hernandez',
			instagram: 'findnew_adventures',
			username: 'findnew_adventures',
			tiktok: 'findnew_adventures',
		},
		{
			imageUrl: '46b18f38-681f-42e3-65c2-299644cf9001',
			name: 'Amy Drohen',
			instagram: 'sushigirl_atx',
			username: 'sushigirl_atx',
			tiktok: 'sushigirl_atx',
		},

		{
			imageUrl: '7a239711-80c8-4d5e-a3e7-13b7fa1b9600',
			name: 'Ana',
			instagram: 'thebucketlistlatina',
			username: 'thebucketlistlatina',
			tiktok: 'thebucketlistlatina',
		},
		// {
		// 	imageUrl: '031a746b-74ad-4287-a194-eb5f7d021900',
		// 	name: 'Sandae Cooke',
		// 	instagram: 'curvygirlontherun',
		// 	username: 'Curvy Girl on the Run',
		// 	tiktok: 'curvygirlontherun',
		// },

		{
			imageUrl: '6bdaa77b-e5ff-4b41-29bb-11bee44e1701',
			name: 'Cindy N.',
			instagram: 'foreign_fresh_fierce',
			username: 'ForeignFreshFierce',
			tiktok: 'foreignfreshfierce',
		},

		{
			imageUrl: '70baa3fa-3ca0-4980-eb97-0710e7e59a00',
			name: 'Bernice Padilla',
			instagram: 'trendytraveler__',
			username: 'TrendyTraveler',
			tiktok: 'trendytraveler',
		},
		{
			imageUrl: 'c933e2c8-cfde-450b-9139-03bb8f72a700',
			name: 'Tanna Wasilchak',
			instagram: 'tannathetraveler',
			username: 'Tannathetraveler',
			tiktok: 'tannawasilchak',
		},
		// {
		// 	imageUrl: '917bfef8-8d5e-404a-9285-d72f83523b01',
		// 	name: 'Lindsey Renken',
		// 	instagram: 'lindseyrenken',
		// 	username: 'lmrenken',
		// 	tiktok: 'lmrenken',
		// },
	]

	// function learnMoreGuide() {
	// 	track('Explore Guides Clicked')
	// 	goto('/guide')
	// }

	// function applyForGuide() {
	// 	track('Apply to Guide Clicked')
	// 	window.open('https://abqi8g6kjwn.typeform.com/to/CY91sRBV', '_blank')
	// }
</script>

<div class="bg-white">
	<div class="max-w-6xl mx-auto pt-12 lg:pt-24 relative isolate">
		<!-- <div
			class="absolute inset-x-0 -top-12 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
			aria-hidden="true"
		>
			<div
				class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-t from-brand-gray-0 to-brand-primary opacity-25"
				style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
			/>
		</div> -->
		<p class="text-center">
			<span class="py-1 text-base font-semibold tracking-wide">Creator Spotlight</span>
		</p>

		<div class="mt-4 grid grid-cols-2 gap-2 md:grid-cols-4 mx-2">
			{#each guides as guide}
				<Guide
					imageUrl={guide.imageUrl}
					name={guide.name}
					username={guide.username}
					instagram={guide.instagram}
					tiktok={guide.tiktok}
				/>
			{/each}
		</div>

		<div
			class="flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 sm:gap-x-4 justify-center mt-8 w-full"
		>
			<!-- <Button theme="dark" class="w-full sm:w-auto" size="sm" on:click={learnMoreGuide}
				>Learn More</Button
			>
			<Button theme="brand" class="w-full sm:w-auto" size="sm" on:click={applyForGuide}
				>Apply to Join</Button
			> -->
			<a
				href="/guide"
				id="all-popular"
				class="inline-flex items-center space-x-2 font-medium leading-6 text-black group ring-transparent focus:outline-none"
			>
				<span>Learn More</span>
				<div
					class="flex items-center justify-center w-4 h-4 transition duration-100 ease-in-out transform translate-x-0 rounded-full group-hover:translate-x-2"
				>
					<Icon src={ArrowRight} class="w-4 h-4" />
				</div>
			</a>
		</div>
	</div>
</div>
