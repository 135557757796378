<script lang="ts">
	import { constructCdnUrl } from '$src/utils/cloudflare-loader'

	export let imageUrl: string
	export let name: string
	export let username: string
	export let instagram: string
	export let tiktok: string
</script>

<div class="flex flex-col justify-center items-center my-4">
	<a href="/profile/{username}" class="group flex flex-col items-center">
		<img
			src={`${constructCdnUrl(imageUrl)}`}
			alt={name}
			class="rounded-full h-36 w-36 border-brand-gray-2 border-2 bg-brand-gray-1 ring-1 ring-brand-gray-2 transition-colors group-hover:ring-brand-gray-3"
		/>
		<p class="text-sm font-medium leading-4 truncate my-2 text-brand-gray-5 group-hover:text-black">
			{name}
		</p>
	</a>
	<div class="flex items-center space-x-1">
		<a
			href={`https://www.instagram.com/${instagram}`}
			target="_blank"
			class="text-xs text-brand-gray-4 font-medium leading-4 truncate"
		>
			<img src="/visuals/icons/social/instagram-logo-blk.svg" alt="" class="h-4 w-4" />
		</a>

		<a
			href={`https://www.tiktok.com/@${tiktok}`}
			target="_blank"
			class="text-xs text-brand-gray-4 font-medium leading-4 truncate"
		>
			<img src="/visuals/icons/social/tiktok-logo-blk.svg" alt="" class="h-4 w-4" />
		</a>
	</div>
</div>
