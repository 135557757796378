<script lang="ts">
	import state from '$lib/stores/auth-state'

	import { getContextClient } from '@urql/svelte'
	import Button from '../buttons/Button.svelte'
	import {
		InsertTripDocument,
		type InsertTripMutation,
		type InsertTripMutationVariables,
	} from '$src/lib/queries/generated/InsertTrip'
	import { track } from '$src/utils/track'
	import { performSignIn } from '$src/lib/authentication/helpers'
	import { clearInsertTripOnLoadParams, loginAndInsertTrip } from '$src/utils/insertTrip'
	import { goto } from '$app/navigation'
	import LaptopFrame from './LaptopFrame.svelte'
	import LaptopPreviewScreen from './LaptopPreviewScreen.svelte'
	import { Icon } from '@steeze-ui/svelte-icon'
	import { ArrowRight } from '@steeze-ui/heroicons'

	const client = getContextClient()

	const insertTripMutation = (vars: InsertTripMutationVariables) =>
		client
			.mutation<InsertTripMutation, InsertTripMutationVariables>(InsertTripDocument, vars)
			.toPromise()

	function handleStartTripClick() {
		track('Start Trip Clicked')
		if ($state.signedIn) {
			handleInsertTrip()
		} else {
			loginAndInsertTrip(
				performSignIn,
				() => handleInsertTrip().then(clearInsertTripOnLoadParams),
				null,
			)

			return Promise.resolve()
		}
	}

	function exploreGuidesClick() {
		track('Explore Guides Clicked')
		goto('/explore')
	}

	async function handleInsertTrip() {
		insertTripMutation({ input: { title: 'New Trip' } })
			.then((res) => {
				if (res?.data?.insertTrip?.id) {
					goto(`/trips/${res?.data?.insertTrip?.id}`)
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}
</script>

<main class="relative isolate pt-6 max-w-screen-xl mx-4 sm:mx-auto mt-8 sm:mt-12 md:mt-16 xl:mt-20">
	<!-- <div
		class="absolute inset-x-0 top-12 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
		aria-hidden="true"
	>
		<div
			class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-brand-gray-0 to-brand-primary opacity-35"
			style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
		/>
	</div>

	<div
		class="absolute inset-x-0 top-12 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
		aria-hidden="true"
	>
		<div
			class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-l from-brand-gray-0 to-brand-mid-gradient opacity-25"
			style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
		/>
	</div> -->
	<div class="flex flex-col items-center justify-center text-center px-4 sm:px-6">
		<!-- <span class="text-black tracking-widest text-sm sm:text-base uppercase">Grand Opening</span> -->
		<h1
			class="mt-1 text-3xl font-extrabold leading-10 tracking-tight text-black sm:leading-none sm:text-4xl md:text-5xl"
		>
			Shop Premium Travel Guides<br class="inline" />
			<!-- <span class="text-brand-primary">Like a Pro</span> -->
		</h1>
		<div class="flex flex-col items-center mt-5 sm:max-w-xl sm:mx-auto sm:text-center">
			<h2 class="text-lg text-pretty font-medium tracking-tight text-black">
				Discover unique destinations, hidden gems, and essential tips—all tried and tested for real
				journeys.
			</h2>
			<div
				class="flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 sm:gap-x-4 justify-center mt-8 w-full"
			>
				<!-- <Button
					theme="dark"
					priority="secondary"
					class="w-full sm:w-auto"
					size="md"
					on:click={handleStartTripClick}>Start Your Trip</Button
				> -->
				<Button
					theme="dark"
					priority="secondary"
					class="w-full sm:w-auto"
					size="md"
					on:click={exploreGuidesClick}>Explore Guides</Button
				>
				<!-- <a
					href="/guide"
					id="all-popular"
					rel="nofollow noopener noreferrer"
					target="_blank"
					class="inline-flex items-center space-x-2 font-medium leading-6 text-black group ring-transparent focus:outline-none"
				>
					<span>Become a Seller</span>
					<div
						class="flex items-center justify-center w-4 h-4 transition duration-100 ease-in-out transform translate-x-0 rounded-full group-hover:translate-x-2"
					>
						<Icon src={ArrowRight} class="w-4 h-4" />
					</div>
				</a> -->
			</div>

			<div class="text-xs text-brand-gray-4 mt-3">
				Instant signup. No credit card required.
				<a href="/legal/terms" class="underline">Terms of Service</a> and
				<a href="/legal/privacy" class="underline">Privacy Policy</a>.
			</div>
		</div>
	</div>
	<!-- <div class="relative hidden max-w-5xl mx-auto mt-12 lg:block">
		<LaptopFrame>
			<LaptopPreviewScreen />
		</LaptopFrame>
	</div>

	<div class="block mt-6 lg:hidden">
		<img
			class="object-cover object-left-top transform translate-x-0 translate-y-6 md:translate-x-0 lg:translate-y-12"
			src={'/visuals/stock/hero-v2.webp'}
			loading="lazy"
			width="1024"
			height="615"
			alt="Airheart App screenshot"
		/>
	</div> -->
</main>
